var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "650px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [_vm._v("VGM WEIGHT CHECK")]),
        _c("div", { staticClass: "content_box" }, [
          _c("table", { staticClass: "tbl_col" }, [
            _vm._m(0),
            _c("tbody", [
              _c("tr", [
                _c("td", { staticStyle: { "text-align": "left" } }, [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "17px",
                        "font-weight": "bold",
                      },
                    },
                    [
                      _vm._v("* TOTAL VGM WEIGHT :"),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("comma")(_vm.parentInfo.vgmWt)) +
                            " KGS"
                        ),
                      ]),
                    ]
                  ),
                  _c("br"),
                  _c("br"),
                  _vm._v(
                    " Please check and tick below clause if above VGM is correct and proceed S/I submit. "
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("p", { staticClass: "mt10" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.agreeYn,
                  expression: "agreeYn",
                },
              ],
              attrs: {
                type: "checkbox",
                id: "agreea1",
                name: "agree",
                "true-value": "Y",
                "false-value": "N",
              },
              domProps: {
                checked: Array.isArray(_vm.agreeYn)
                  ? _vm._i(_vm.agreeYn, null) > -1
                  : _vm._q(_vm.agreeYn, "Y"),
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.agreeYn,
                    $$el = $event.target,
                    $$c = $$el.checked ? "Y" : "N"
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.agreeYn = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.agreeYn = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.agreeYn = $$c
                  }
                },
              },
            }),
            _c(
              "label",
              { staticClass: "color_black", attrs: { for: "agreea1" } },
              [
                _vm._v(
                  " Shipper shall bear full responsibility for declaration of Verified Gross Mass (VGM), and shall be solely liable for any and all damages, losses, expenses, penalties and fees to the Carrier resulting from the non-compliance of VGM regulation or in any event that delaying or providing inaccuracy of VGM. "
                ),
              ]
            ),
          ]),
        ]),
        _c("div", { staticClass: "text_center mt10" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.goModify()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONEX060P011.007")))]
          ),
          _c(
            "a",
            {
              staticClass: "button gray lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONEX060P011.008")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "50%" } })])
  },
]
render._withStripped = true

export { render, staticRenderFns }