<template>
  <div class="popup_wrap" style="width:650px;">
    <button type="button" class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont" >
      <h1 class="page_title">VGM WEIGHT CHECK<!--VGM WEIGHT CHECK--></h1>
      <div class="content_box">
        <table class="tbl_col">
          <colgroup>
            <col width="50%">
          </colgroup>
          <tbody>
            <tr>
              <td style="text-align:left"><span style="font-size: 17px; font-weight: bold;">* TOTAL VGM WEIGHT :<span style="color: red"> {{ parentInfo.vgmWt | comma }} KGS</span></span><br/><br/>
              Please check and tick below clause if above VGM is correct and proceed S/I submit.
              </td>
            </tr>
          </tbody>
        </table>

        <p class="mt10">
          <input type="checkbox" id="agreea1" name="agree" v-model="agreeYn" true-value="Y" false-value="N">
          <label for="agreea1" class="color_black">
            Shipper shall bear full responsibility for declaration of Verified Gross Mass (VGM), and shall be solely liable for any and all damages, losses, expenses, penalties and fees to the Carrier resulting from the non-compliance of VGM regulation or in any event that delaying or providing inaccuracy of VGM.
          </label>

        </p>
      </div>
      <div class="text_center mt10">
        <a href="#" class="button blue lg" @click.prevent="goModify()">{{ $t('msg.ONEX060P011.007') }}<!-- 확인 --></a>
        <a href="#" class="button gray lg" @click.prevent="$emit('close')">{{ $t('msg.ONEX060P011.008') }}<!-- 취소 --></a>
      </div>
    </div>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'

export default {
  name: 'BLModifyNotiPop',
  props: {
    parentInfo: {
      type: Object,
      default () {
        return {
          vgmWt: '',
          saveType: ''
        }
      }
    }
  },
  data () {
    return {
      agreeYn: 'N'
    }
  },
  computed: {
    ...rootComputed
  },
  filters: {
    comma (val) {
      var num = Number(val)
      return num.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  methods: {
    goModify () {
      // console.log(this.$props.parentInfo)

      if (this.agreeYn !== 'Y') {
        this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('art.CMATK335') }) // 약관에 동의 하여야 합니다.
        return
      }

      this.$emit('selectFunc', this.$props.parentInfo)
    }
  }
}
</script>
